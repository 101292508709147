// Dependencies
import React from 'react';
import { observer } from 'mobx-react';
import useStores from '../src/helpers/useStores';

// Components
import {
	Page,
	NavBar,
	Hero,
	Footer,
	WhoIsSection,
	WhatIsProductSection,
	ErrorMessage,
} from '../src/components';

// Data
import links from '../data/navbar-links';

// Helpers
import { redirectIfLoggedIn } from '../src/helpers/redirect';

// Styling
import '../styles/index.scss';

const Home = observer(() => {
	const { errorStore } = useStores();
	return redirectIfLoggedIn(
		useStores(),
		<Page>
			<NavBar links={links} />
			<ErrorMessage error={errorStore.error} onClose={errorStore.clear} />
			<Hero scrollTo="#who-is-section" />
			<WhoIsSection />
			<WhatIsProductSection />
			<Footer />
		</Page>
	);
});

export default Home;
